import { Alert, Box, Paper, Stack, Typography } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
  defaultErrorMessage?: string;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

/**
 * Error boundary component that catches errors in its child component tree.
 * Note: Needs to be a class component for catching all errors.
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error to an error reporting service here
    console.error('Error caught by ErrorBoundary:', error);
    console.error('Component stack:', errorInfo.componentStack);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Render fallback UI if provided
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return <DefaultError error={this.state.error} defaultErrorMessage={this.props.defaultErrorMessage} />;
    }

    return this.props.children;
  }
}

type DefaultErrorProps = {
  error: Error | null;
  defaultErrorMessage: string | undefined;
}

/**
 * Error component specifically for only part of the UI failing
 */
export function DefaultError({ error, defaultErrorMessage }: DefaultErrorProps) {
  const errorMessage = error?.message || defaultErrorMessage || 'An unknown error occurred';

  return (
    <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: '600px',
          width: '100%',
          borderRadius: 2
        }}
      >
        <Stack spacing={3} alignItems="center">
          <Typography variant="h5" component="h2" align="center" fontWeight="bold">
            Something went wrong
          </Typography>
          <Alert severity="error" variant="outlined" sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Stack>
      </Paper>
    </Box>
  );
}

export default ErrorBoundary;
