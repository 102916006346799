import { useState } from "react";
import { toast } from "sonner";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { CancelBtn, PrimaryBtn } from "../components";
import { useRevalidator } from "@remix-run/react";
import { ApiResponse } from "~/server/utils";

type useConfirmModalReturn = [() => void, () => JSX.Element];

const useConfirmModal = (
  requestFn: (...args: string[]) => Promise<unknown>,
  deleteArgs: string[],
  name: string,
  confirmMsg: string = `Delete ${name}?`,
  toastMsg: string = `"${name}" Deleted`,
  errorMsg?: string
): useConfirmModalReturn => {
  const { revalidate } = useRevalidator();

  const [showModal, setShowModal] = useState<boolean>(false);

  const showConfirmModal = () => setShowModal(true);

  const onConfirm = async () => {
    try {
      setShowModal(false);
      const res = (await requestFn(...deleteArgs)) as ApiResponse<unknown>;
      if (res.failure) {
        throw new Error(res.errorMessage);
      }

      // to be removed ater switch to graphql
      revalidate();
      toast.success(toastMsg);
    } catch (e) {
      //@ts-ignore
      toast.error(e?.message || errorMsg || "An Error has occurred");
    }
  };

  const DeleteModal = () => (
    <Dialog open={showModal}>
      <DialogContent>
        <DialogContentText>
          <span className="text-md">{confirmMsg}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="flex flex-col gap-2 flex-grow">
          <PrimaryBtn onClick={onConfirm} text="Confirm" />
          <CancelBtn onClick={() => setShowModal(false)} />
        </div>
      </DialogActions>
    </Dialog>
  );

  return [showConfirmModal, DeleteModal];
};

export default useConfirmModal;
