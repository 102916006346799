import { useEffect, useMemo } from "react";
import { useRevalidator } from "react-router-dom";

const useCloseOnReload = (onClose: () => void) => {
  const revalidator = useRevalidator();

  const isRevalidating = useMemo(
    () => revalidator.state === "loading",
    [revalidator.state]
  );

  useEffect(() => {
    if (isRevalidating) {
      onClose();
    }
  }, [isRevalidating, onClose]);
};

export default useCloseOnReload;
