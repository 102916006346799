import axios from "axios";
import { ToastMessage } from "../hooks/useToast";
import { Toast } from "~/types";
import { getValueFromOptions, IsObject, PlainObject } from "../utils/tsUtils";
import { logRequest } from "../utils/utils";

export const HEADERS = {
  cmskey: "icTg3r2c-Bh5x-ys6ZjRA-k9Z6vc",
  Accept: "application/vnd.kptncook.mobile-v9+json",
};

export const CMS_ENV_VALUES = ["staging", "production", "development"] as const;
export type CMSEnv = (typeof CMS_ENV_VALUES)[number];
export function getCMSEnvValue(x: unknown): CMSEnv | undefined {
  return getValueFromOptions(CMS_ENV_VALUES, x);
}

export function getCMSEnvironment(): CMSEnv {
  //  ENV: { ENVIRONMENT: process.env.ENVIRONMENT }
  if (IsOnServer()) {
    const cmsEnv = getCMSEnvValue(process.env.ENVIRONMENT);
    if (cmsEnv === undefined) {
      throw new Error(`Couldn't get valid ENVIRONMENT from process env`);
    }
    return cmsEnv;
  }

  // We are in the browser
  if (
    !("env" in window && IsObject(window?.env) && "ENVIRONMENT" in window?.env)
  ) {
    throw new Error("Couldn't get ENVIRONMENT from window.env");
  }

  const cmsEnv = getCMSEnvValue(window.env.ENVIRONMENT);

  if (cmsEnv === undefined) {
    throw new Error(`Couldn't get valid ENVIRONMENT from window env`);
  }
  return cmsEnv;
}

export type Path = string;

// According to Axios documentation:
// > params` are the URL parameters to be sent with the request
// > Must be a plain object or a URLSearchParams object
// > NOTE: params that are null or undefined are not rendered in the URL.
// source: https://axios-http.com/docs/req_config
export type RequestParams = PlainObject | URLSearchParams;

// There is no documentation on the type of headers in Axios, but this should be a helpful type
export type HttpHeaders = PlainObject;
export type ReqData = NonNullable<object>;

export type RequestDataParams = {
  path: string;
  request: Request;
  params?: RequestParams;
};

export function IsOnServer(): boolean {
  // If window is defined, we are in the browser and otherwise in the server
  return typeof window === "undefined";
}

export const errorCodes = [400, 401, 403, 404, 500] as const;
export type ErrorCode = (typeof errorCodes)[number];
export const successCodes = [200, 201, 204] as const;
export type SuccessCode = (typeof successCodes)[number];

export type SuccessResponse<T> = { success: true; failure: false; data: T };
export function createSuccessResponse<T>(data: T): SuccessResponse<T> {
  return { success: true, failure: false, data };
}

export type ErrorResponse = {
  success: false;
  failure: true;
  errorMessage: string;
  status: number;
};
export function createErrorResponse({
  errorMessage,
  status = 500,
}: {
  errorMessage: string;
  status: number;
}): ErrorResponse {
  return { success: false, failure: true, errorMessage, status };
}

export type ApiResponse<T> = SuccessResponse<T> | ErrorResponse;

export function createOldToastMessage(
  response: ApiResponse<unknown>,
  customSuccessMessage?: string,
  customErrorMessage?: string
): Toast {
  if (response.failure) {
    return {
      msg: customErrorMessage ?? response.errorMessage,
      status: response.status,
    };
  }
  return { msg: customSuccessMessage ?? "Success", status: 200 };
}

export function createToastMessage(
  response: ApiResponse<unknown>,
  customSuccessMessage?: string,
  customErrorMessage?: string
): ToastMessage {
  if (response.failure) {
    return { msg: customErrorMessage ?? response.errorMessage, success: false };
  }
  return { msg: customSuccessMessage ?? "Success", success: true };
}

export function handleAxiosException(exception: unknown): {
  status: number;
  data: unknown;
} {
  logRequest(exception);
  if (!axios.isAxiosError(exception)) {
    return {
      status: 500,
      data: { error: "Internal Server Error" },
    };
  }

  // Similar to Axios docs: https://axios-http.com/docs/handling_errors
  if (exception.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return exception.response;
  }

  if (exception.request) {
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
      status: 500,
      data: {
        error: "The request was made but no response was received",
        request: exception.request as unknown,
      },
    };
  }

  // Something happened in setting up the request that triggered an Error
  return {
    status: 500,
    data: { error: exception.message },
  };
}

export function getErrorFromAxiosException(exception: unknown): ErrorResponse {
  logRequest(exception);
  if (!axios.isAxiosError(exception)) {
    return createErrorResponse({
      status: 500,
      errorMessage:
        "Internal Server Error -- Unknown exception, expected AxiosError",
    });
  }

  // Similar to Axios docs: https://axios-http.com/docs/handling_errors
  if (exception.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return createErrorResponse({
      status: exception.response.status,
      errorMessage: JSON.stringify(exception.response.data),
    });
  }

  if (exception.request !== undefined) {
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return createErrorResponse({
      status: 500,
      errorMessage: "The request was made but no response was received",
    });
  }

  // Something happened in setting up the request that triggered an Error
  return createErrorResponse({
    status: 500,
    errorMessage: exception.message,
  });
}

export const cleanData = <T extends Record<string, unknown>>(data: T) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [
      key,
      typeof value === "string" && value === "" ? null : value,
    ])
  ) as T;
