import LangSelect from "~/components/Select/LangSelect";
import Navbar from "~/layouts/Navbar";
import useLocalStorage from "./useLocalStorage";
import { Lang } from "~/types";
import { Box } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  title?: string;
  nestedTitle?: string;
  titleLink?: string;
  nestedTitleLink?: string;
  slugIsId?: boolean;
};

export const useNavbarWithLang = () => {
  const [lang, setLang] = useLocalStorage<Lang>("lang", "en");

  const NavbarWithLang = ({
    children,
    title,
    nestedTitle,
    titleLink,
    nestedTitleLink,
    slugIsId,
  }: Props) => (
    <Navbar
      title={title}
      nestedTitle={nestedTitle}
      titleLink={titleLink}
      nestedTitleLink={nestedTitleLink}
      slugIsId={slugIsId}
    >
      {children}
      <LangSelect
        defaultLang={lang}
        handleLangChange={(selectedLang) => setLang(selectedLang)}
      />
    </Navbar>
  );

  return { Navbar: NavbarWithLang, lang };
};

export default useNavbarWithLang;
